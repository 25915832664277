<template>
  <div>
    <tasks-sidebar></tasks-sidebar>
    <router-view></router-view>
  </div>
</template>

<script>
import TasksSidebar from "@/components/sidebar/TasksSidebar";
export default {
  components: {
    TasksSidebar,
  },
};
</script>
