<template>
  <v-navigation-drawer
    permanent
    color="primary"
    v-model="drawer"
    app
    clipped
    left
    v-if="getMe.menu_access"
    style="max-height: 100vh"
  >
    <v-list dense dark v-if="!loading">
      <v-list-item-group v-model="model" mandatory>
        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'Bookings' }"
          v-if="getMe.menu_access.tasks.visible && !hasHiddenBookings"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Bookings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'Tasks' }"
          v-if="getMe.menu_access.tasks.visible"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-hammer</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Tasks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'TasksTemplate' }"
          v-if="true"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-checkbox-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Checklists</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'Automations' }"
          v-if="canSeeAutomation"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-timer-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Automations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'Maintenance' }"
          v-if="canSeeAutomation"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-hammer-wrench</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Maintenance</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'Documents' }"
          v-if="canSeeAutomation"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Documents</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="white--text navigation-a-underline--remove"
          :to="{ name: 'Inventory' }"
          v-if="canSeeAutomation"
        >
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-folder-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Inventory</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { USER_CRM_REQUEST } from "@/store/actions/user";
// import { AUTH_LOGOUT } from "@/store/actions/auth";
export default {
  data: () => ({
    drawer: null,
    loading: true,
    model: null,
    canSeeAutomation: false,
    hasHiddenBookings: false,
    drawerStatus: [],
  }),

  created: function() {
    if (Object.keys(this.getMe).length === 0) {
      this.$store.dispatch(USER_CRM_REQUEST).then((resp) => {
        this.handleDrawer();
      });
    } else {
      this.handleDrawer();
    }
  },

  computed: {
    ...mapGetters(["getDrawerStatus", "getMe"]),
  },

  watch: {
    $route(val) {
      this.drawerStatus.forEach((element, index) => {
        if (this.subMenuActive(element)) {
          this.model = index;
        }
      });
    },
  },

  methods: {
    handleDrawer() {
      this.calculatePermissions();
      if (this.hasHiddenBookings) {
        this.drawerStatus = ["Tasks", "Templates"];
      } else {
        this.drawerStatus = ["Bookings", "Tasks", "Templates"];
      }

      this.drawerStatus.forEach((element, index) => {
        if (this.subMenuActive(element)) {
          this.model = index;
        }
      });
      this.loading = false;
    },

    calculatePermissions() {
      if (Object.keys(this.getMe).length === 0) {
        return;
      }
      Object.entries(this.getMe.features).forEach(([key, value]) => {
        if (key === "can_see_automation") {
          this.canSeeAutomation = value;
        }
        if (key === "has_hidden_bookings") {
          this.hasHiddenBookings = value;
        }
      });
    },
    subMenuActive(input) {
      return this.$route.meta.menu === input;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.navigation-a-underline--remove {
  text-decoration: none;
}
</style>
